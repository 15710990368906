export function getTime(value) {
  const time = new Date();
  if (value) {
    const futureTime = time.getTime() + 1000 * 60 * 60 * 24 * value;
    time.setTime(futureTime);
  }
  let year = time.getFullYear();
  let month = time.getMonth() + 1;
  let day = time.getDate();
  if (month <= 9) {
    month = "0" + month;
  }
  if (day <= 9) {
    day = "0" + day;
  }
  return `${year}-${month}-${day}`;
}
